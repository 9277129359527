import { joinURL } from "ufo";
import type { ProviderGetImage } from "@nuxt/image";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { createOperationsGenerator } from "#image";

const operationsGenerator = createOperationsGenerator();

const format = {
    width: "w",
    height: "h",
    quality: "q",
    format: "f",
};

export const getImage: ProviderGetImage = (
    src,
    { modifiers = {}, baseURL } = {},
) => {
    if (!baseURL) {
        baseURL = useRuntimeConfig().public.siteUrl;
    }

    let operations = operationsGenerator(modifiers);

    for (const formatKey in format) {
        operations = (operations as string)
            .replaceAll(formatKey, format[formatKey as keyof typeof format])
            .replaceAll("/", ",");
    }

    return {
        url: joinURL(baseURL, operations ? src + "/" + `${operations}` : src),
    };
};
