import revive_payload_client_4sVQNw7RlN from "/insocial-landing/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/insocial-landing/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/insocial-landing/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/insocial-landing/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/insocial-landing/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/insocial-landing/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/insocial-landing/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/insocial-landing/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/insocial-landing/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/insocial-landing/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_A0OWXRrUgq from "/insocial-landing/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/insocial-landing/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/insocial-landing/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_adVF2uRka6 from "/insocial-landing/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import fontawesome_a3gDgrBaWH from "/insocial-landing/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.mjs";
import plugin_iGLjBbor9q from "/insocial-landing/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_1UohGbtF8v from "/insocial-landing/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import vue3_google_map_1fnrZMtPlO from "/insocial-landing/plugins/vue3-google-map.js";
import BugsnagPerformance_client_fr0FmDntL4 from "/insocial-landing/plugins/BugsnagPerformance.client.ts";
import Vue3Lottie_client_bMZiOS6AB0 from "/insocial-landing/plugins/Vue3Lottie.client.ts";
import apexcharts_client_xo3MRJYPBX from "/insocial-landing/plugins/apexcharts.client.js";
import vue_query_wrmMkNpEpe from "/insocial-landing/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_adVF2uRka6,
  fontawesome_a3gDgrBaWH,
  plugin_iGLjBbor9q,
  plugin_1UohGbtF8v,
  vue3_google_map_1fnrZMtPlO,
  BugsnagPerformance_client_fr0FmDntL4,
  Vue3Lottie_client_bMZiOS6AB0,
  apexcharts_client_xo3MRJYPBX,
  vue_query_wrmMkNpEpe
]