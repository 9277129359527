import {
  faStar as freeFasFaStar,
  faMagnifyingGlass as freeFasFaMagnifyingGlass,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronRight as freeFasFaChevronRight
} from '@fortawesome/free-solid-svg-icons'

import {
  faStar as freeFarFaStar
} from '@fortawesome/free-regular-svg-icons'

export default {freeFasFaStar, freeFasFaMagnifyingGlass, freeFasFaChevronLeft, freeFasFaChevronRight, freeFarFaStar}