import BugsnagPerformance from "@bugsnag/browser-performance";
import { VueRouterRoutingProvider } from "@bugsnag/vue-router-performance";
import type { RuntimeConfig } from "@nuxt/schema";

export default defineNuxtPlugin(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const config: RuntimeConfig = useRuntimeConfig();
    const options = config.public.bugsnag;

    const router = useRouter();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    options.performanceConfig.routingProvider = new VueRouterRoutingProvider(
        router,
    );

    BugsnagPerformance.start(options as never);
});
