import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAboutPageAboutAuthenticity, LazySvgoAboutPageAboutCommunity, LazySvgoAboutPageAboutData, LazySvgoAboutPageAboutEmpowerment, LazySvgoAboutPageAboutEngagement, LazySvgoAboutPageAboutHassle, LazySvgoAboutPageAboutIncreased, LazySvgoAboutPageAboutInfluencer, LazySvgoAboutPageAboutInnovation, LazySvgoAboutPageAboutIntegrity, LazySvgoAboutPageAboutTargeted, LazySvgoAboutPageAboutTime, LazySvgoBlogIconsArrowDown, LazySvgoBlogIconsArrowRightAuthor, LazySvgoBlogIconsComments, LazySvgoBlogIconsInstagramIcon, LazySvgoBlogIconsLike, LazySvgoBlogIconsSocialX, LazySvgoBlogIconsSocialX28, LazySvgoBlogIconsSocialBlogger28, LazySvgoBlogIconsSocialFacebook, LazySvgoBlogIconsSocialFacebook28, LazySvgoBlogIconsSocialInstagram, LazySvgoBlogIconsSocialLinkedin28, LazySvgoBlogIconsSocialMedium28, LazySvgoBlogIconsSocialPinterest28, LazySvgoBlogIconsSocialReddit28, LazySvgoBlogIconsSocialYoutube, LazySvgoBlogIconsViews, LazySvgoIconsSprite, LazySvgoOtherArrowDown, LazySvgoOtherArrowDown32px, LazySvgoOtherChat, LazySvgoOtherFakeChackbox, LazySvgoOtherFeedback, LazySvgoOtherHeadset, LazySvgoOtherLockLinear, LazySvgoOtherMail, LazySvgoOtherOrderCart, LazySvgoOtherUserVerified, LazySvgoPaymentsAmericanExpress, LazySvgoPaymentsMastercard, LazySvgoPaymentsPaypal, LazySvgoPaymentsVisa, LazySvgoProductsAutoBoostIcon, LazySvgoProductsAutoBoostIconDisabled, LazySvgoProductsAutoPromotionIcon, LazySvgoProductsAutoPromotionIconDisabled, LazySvgoProductsDirectIcon, LazySvgoProductsDirectIconDisabled, LazySvgoProductsHashtagGeneratorIcon, LazySvgoProductsHashtagGeneratorIconDisabled, LazySvgoProductsInfluencerIcon, LazySvgoProductsInfluencerIconDisabled, LazySvgoProductsPostingIcon, LazySvgoProductsPostingIconDisabled, LazySvgoProductsTrackerIcon, LazySvgoProductsTrackerIconDisabled, LazySvgoSocialFacebook, LazySvgoSocialInstagram, LazySvgoSocialLinkedin, LazySvgoSocialX, LazySvgoSocialYoutube } from '#components'
const lazyGlobalComponents = [
  ["SvgoAboutPageAboutAuthenticity", LazySvgoAboutPageAboutAuthenticity],
["SvgoAboutPageAboutCommunity", LazySvgoAboutPageAboutCommunity],
["SvgoAboutPageAboutData", LazySvgoAboutPageAboutData],
["SvgoAboutPageAboutEmpowerment", LazySvgoAboutPageAboutEmpowerment],
["SvgoAboutPageAboutEngagement", LazySvgoAboutPageAboutEngagement],
["SvgoAboutPageAboutHassle", LazySvgoAboutPageAboutHassle],
["SvgoAboutPageAboutIncreased", LazySvgoAboutPageAboutIncreased],
["SvgoAboutPageAboutInfluencer", LazySvgoAboutPageAboutInfluencer],
["SvgoAboutPageAboutInnovation", LazySvgoAboutPageAboutInnovation],
["SvgoAboutPageAboutIntegrity", LazySvgoAboutPageAboutIntegrity],
["SvgoAboutPageAboutTargeted", LazySvgoAboutPageAboutTargeted],
["SvgoAboutPageAboutTime", LazySvgoAboutPageAboutTime],
["SvgoBlogIconsArrowDown", LazySvgoBlogIconsArrowDown],
["SvgoBlogIconsArrowRightAuthor", LazySvgoBlogIconsArrowRightAuthor],
["SvgoBlogIconsComments", LazySvgoBlogIconsComments],
["SvgoBlogIconsInstagramIcon", LazySvgoBlogIconsInstagramIcon],
["SvgoBlogIconsLike", LazySvgoBlogIconsLike],
["SvgoBlogIconsSocialX", LazySvgoBlogIconsSocialX],
["SvgoBlogIconsSocialX28", LazySvgoBlogIconsSocialX28],
["SvgoBlogIconsSocialBlogger28", LazySvgoBlogIconsSocialBlogger28],
["SvgoBlogIconsSocialFacebook", LazySvgoBlogIconsSocialFacebook],
["SvgoBlogIconsSocialFacebook28", LazySvgoBlogIconsSocialFacebook28],
["SvgoBlogIconsSocialInstagram", LazySvgoBlogIconsSocialInstagram],
["SvgoBlogIconsSocialLinkedin28", LazySvgoBlogIconsSocialLinkedin28],
["SvgoBlogIconsSocialMedium28", LazySvgoBlogIconsSocialMedium28],
["SvgoBlogIconsSocialPinterest28", LazySvgoBlogIconsSocialPinterest28],
["SvgoBlogIconsSocialReddit28", LazySvgoBlogIconsSocialReddit28],
["SvgoBlogIconsSocialYoutube", LazySvgoBlogIconsSocialYoutube],
["SvgoBlogIconsViews", LazySvgoBlogIconsViews],
["SvgoIconsSprite", LazySvgoIconsSprite],
["SvgoOtherArrowDown", LazySvgoOtherArrowDown],
["SvgoOtherArrowDown32px", LazySvgoOtherArrowDown32px],
["SvgoOtherChat", LazySvgoOtherChat],
["SvgoOtherFakeChackbox", LazySvgoOtherFakeChackbox],
["SvgoOtherFeedback", LazySvgoOtherFeedback],
["SvgoOtherHeadset", LazySvgoOtherHeadset],
["SvgoOtherLockLinear", LazySvgoOtherLockLinear],
["SvgoOtherMail", LazySvgoOtherMail],
["SvgoOtherOrderCart", LazySvgoOtherOrderCart],
["SvgoOtherUserVerified", LazySvgoOtherUserVerified],
["SvgoPaymentsAmericanExpress", LazySvgoPaymentsAmericanExpress],
["SvgoPaymentsMastercard", LazySvgoPaymentsMastercard],
["SvgoPaymentsPaypal", LazySvgoPaymentsPaypal],
["SvgoPaymentsVisa", LazySvgoPaymentsVisa],
["SvgoProductsAutoBoostIcon", LazySvgoProductsAutoBoostIcon],
["SvgoProductsAutoBoostIconDisabled", LazySvgoProductsAutoBoostIconDisabled],
["SvgoProductsAutoPromotionIcon", LazySvgoProductsAutoPromotionIcon],
["SvgoProductsAutoPromotionIconDisabled", LazySvgoProductsAutoPromotionIconDisabled],
["SvgoProductsDirectIcon", LazySvgoProductsDirectIcon],
["SvgoProductsDirectIconDisabled", LazySvgoProductsDirectIconDisabled],
["SvgoProductsHashtagGeneratorIcon", LazySvgoProductsHashtagGeneratorIcon],
["SvgoProductsHashtagGeneratorIconDisabled", LazySvgoProductsHashtagGeneratorIconDisabled],
["SvgoProductsInfluencerIcon", LazySvgoProductsInfluencerIcon],
["SvgoProductsInfluencerIconDisabled", LazySvgoProductsInfluencerIconDisabled],
["SvgoProductsPostingIcon", LazySvgoProductsPostingIcon],
["SvgoProductsPostingIconDisabled", LazySvgoProductsPostingIconDisabled],
["SvgoProductsTrackerIcon", LazySvgoProductsTrackerIcon],
["SvgoProductsTrackerIconDisabled", LazySvgoProductsTrackerIconDisabled],
["SvgoSocialFacebook", LazySvgoSocialFacebook],
["SvgoSocialInstagram", LazySvgoSocialInstagram],
["SvgoSocialLinkedin", LazySvgoSocialLinkedin],
["SvgoSocialX", LazySvgoSocialX],
["SvgoSocialYoutube", LazySvgoSocialYoutube],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
