import type { RouterConfig } from "@nuxt/schema";
export default <RouterConfig>{
    scrollBehavior: async (to, from, savedPosition) => {
        const headerHeight = useCurrentHeaderHeight();
        const scrollOffset = headerHeight + 40;
        if (to.hash) {
            return await new Promise((resolve) => {
                const timeOut = setTimeout(
                    () => {
                        resolve({
                            el: to.hash,
                            behavior: "smooth",
                            top: scrollOffset,
                        });

                        clearTimeout(timeOut);
                    },
                    from.path === "/" ? 0 : 450,
                );
            });
        }

        if (to.path === from.path) {
            return {
                left: savedPosition?.left,
                top: savedPosition?.top,
                behavior: "smooth",
            };
        }

        return await new Promise((resolve) => {
            if (to.path.includes("blog")) {
                resolve({
                    left: savedPosition?.left || 0,
                    top: savedPosition?.top || 0,
                    behavior: "instant",
                });
            }

            const timeOut = setTimeout(
                () => {
                    resolve({
                        left: savedPosition?.left || 0,
                        top: savedPosition?.top || 0,
                    });

                    clearTimeout(timeOut);
                },
                from.path === "/" ? 0 : 300,
            );
        });
    },
};
