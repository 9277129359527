import validate from "/insocial-landing/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailling_45slash_45global from "/insocial-landing/middleware/redirect-trailling-slash.global.ts";
import manifest_45route_45rule from "/insocial-landing/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailling_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-product-params": () => import("/insocial-landing/middleware/check-product-params.ts"),
  "cyg-access": () => import("/insocial-landing/middleware/cyg-access.ts")
}