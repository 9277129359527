import { default as _91_46_46_46404_93KtX0BOEjVkMeta } from "/insocial-landing/pages/[...404].vue?macro=true";
import { default as about_45usyEyC12J1bZMeta } from "/insocial-landing/pages/about-us.vue?macro=true";
import { default as _91slug_93OQBIF88YZNMeta } from "/insocial-landing/pages/blog/[slug].vue?macro=true";
import { default as _91name_93jomF76npzsMeta } from "/insocial-landing/pages/blog/author/[name].vue?macro=true";
import { default as _91category_93ldbIgL223xMeta } from "/insocial-landing/pages/blog/category/[category].vue?macro=true";
import { default as indexsrLAqEK4CdMeta } from "/insocial-landing/pages/blog/index.vue?macro=true";
import { default as _91tagName_934GUkh3NH4YMeta } from "/insocial-landing/pages/blog/tag/[tagName].vue?macro=true";
import { default as careersWgFBl8jREaMeta } from "/insocial-landing/pages/careers.vue?macro=true";
import { default as contact_45us7dKG4Pxb0rMeta } from "/insocial-landing/pages/contact-us.vue?macro=true";
import { default as disclaimerixZph3IKwiMeta } from "/insocial-landing/pages/disclaimer.vue?macro=true";
import { default as faqNTCOkqTM3VMeta } from "/insocial-landing/pages/faq.vue?macro=true";
import { default as gdprp8gEni0pmTMeta } from "/insocial-landing/pages/gdpr.vue?macro=true";
import { default as _91pk_93huz5Mhq2RyMeta } from "/insocial-landing/pages/growth/[pk].vue?macro=true";
import { default as index9weIG3AwXtMeta } from "/insocial-landing/pages/index.vue?macro=true";
import { default as privacy7MzzBlHYYOMeta } from "/insocial-landing/pages/privacy.vue?macro=true";
import { default as _91product_932dw5xBtjkpMeta } from "/insocial-landing/pages/products/[product].vue?macro=true";
import { default as sitemapE2mB3HFsj9Meta } from "/insocial-landing/pages/sitemap.vue?macro=true";
import { default as teamim6dpwaZNAMeta } from "/insocial-landing/pages/team.vue?macro=true";
import { default as termsOAIXjGJe8wMeta } from "/insocial-landing/pages/terms.vue?macro=true";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    meta: _91_46_46_46404_93KtX0BOEjVkMeta || {},
    component: () => import("/insocial-landing/pages/[...404].vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    meta: about_45usyEyC12J1bZMeta || {},
    component: () => import("/insocial-landing/pages/about-us.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93OQBIF88YZNMeta || {},
    component: () => import("/insocial-landing/pages/blog/[slug].vue")
  },
  {
    name: "blog-author-name",
    path: "/blog/author/:name()",
    meta: _91name_93jomF76npzsMeta || {},
    component: () => import("/insocial-landing/pages/blog/author/[name].vue")
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    meta: _91category_93ldbIgL223xMeta || {},
    component: () => import("/insocial-landing/pages/blog/category/[category].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexsrLAqEK4CdMeta || {},
    component: () => import("/insocial-landing/pages/blog/index.vue")
  },
  {
    name: "blog-tag-tagName",
    path: "/blog/tag/:tagName()",
    meta: _91tagName_934GUkh3NH4YMeta || {},
    component: () => import("/insocial-landing/pages/blog/tag/[tagName].vue")
  },
  {
    name: "careers",
    path: "/careers",
    meta: careersWgFBl8jREaMeta || {},
    component: () => import("/insocial-landing/pages/careers.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: contact_45us7dKG4Pxb0rMeta || {},
    component: () => import("/insocial-landing/pages/contact-us.vue")
  },
  {
    name: "disclaimer",
    path: "/disclaimer",
    meta: disclaimerixZph3IKwiMeta || {},
    component: () => import("/insocial-landing/pages/disclaimer.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqNTCOkqTM3VMeta || {},
    component: () => import("/insocial-landing/pages/faq.vue")
  },
  {
    name: "gdpr",
    path: "/gdpr",
    meta: gdprp8gEni0pmTMeta || {},
    component: () => import("/insocial-landing/pages/gdpr.vue")
  },
  {
    name: "growth-pk",
    path: "/growth/:pk()",
    meta: _91pk_93huz5Mhq2RyMeta || {},
    component: () => import("/insocial-landing/pages/growth/[pk].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index9weIG3AwXtMeta || {},
    component: () => import("/insocial-landing/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy7MzzBlHYYOMeta || {},
    component: () => import("/insocial-landing/pages/privacy.vue")
  },
  {
    name: "products-product",
    path: "/products/:product()",
    meta: _91product_932dw5xBtjkpMeta || {},
    component: () => import("/insocial-landing/pages/products/[product].vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: sitemapE2mB3HFsj9Meta || {},
    component: () => import("/insocial-landing/pages/sitemap.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamim6dpwaZNAMeta || {},
    component: () => import("/insocial-landing/pages/team.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsOAIXjGJe8wMeta || {},
    component: () => import("/insocial-landing/pages/terms.vue")
  }
]