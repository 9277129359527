<template>
    <div class="robot">
        <NuxtImg
            class="robot--part hand--rh"
            :src="robot.rhand"
            :style="stylesRH"
            provider="customCloudflare"
        />
        <NuxtImg
            :style="stylesBody"
            class="robot--part body"
            :src="robot.body"
            provider="customCloudflare"
        />
        <NuxtImg
            :style="stylesLH"
            class="robot--part hand--lh"
            :src="robot.lhand"
            provider="customCloudflare"
        />
    </div>
</template>

<script setup lang="ts">
import { useParallax, useMediaQuery } from "@vueuse/core";
import { robot } from "../../configs/getImagesNamesOnCloudflare";

const container = ref<HTMLElement>();

const isMobile = useMediaQuery("(max-width: 1024px)");
const { tilt, roll } = useParallax(container);

const stylesRH = computed(() => {
    if (isMobile.value) return "";

    return {
        transform: `translateX(${(tilt.value as number) * 20}px) translateY(${
            (roll.value as number) * -25
        }px) scale(1) rotate(${(roll.value as number) * 15}deg)`,
    };
});

const stylesLH = computed(() => {
    if (isMobile.value) return "";

    return {
        transform: `translateX(${(tilt.value as number) * -23}px) translateY(${
            (roll.value as number) * -45
        }px) scale(1) rotate(${(roll.value as number) * 15}deg)`,
    };
});

const stylesBody = computed(() => {
    if (isMobile.value) return "";

    return {
        transform: `translateX(${(tilt.value as number) * 20}px) translateY(${
            (roll.value as number) * 20
        }px) scale(1)`,
    };
});

onMounted(() => {
    container.value = document.getElementsByTagName("body")[0];
});
</script>

<style scoped lang="scss">
.robot {
    position: relative;
    width: clamp(11.375rem, 3.726rem + 21.138vw, 22.75rem);
    height: clamp(11.375rem, 3.726rem + 21.138vw, 22.75rem);
    &--part {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.body {
    left: 0;
    bottom: 0;
    width: clamp(11.375rem, 3.726rem + 21.138vw, 22.75rem);
    height: clamp(11.375rem, 3.726rem + 21.138vw, 22.75rem);
}

.hand {
    &--rh {
        top: 0;
        left: 0;
        width: clamp(11.375rem, 5.688rem + 18.958vw, 22.75rem);
        height: clamp(11.375rem, 5.688rem + 18.958vw, 22.75rem);
    }
    &--lh {
        top: 0;
        left: 0;
        width: clamp(11.375rem, 5.688rem + 18.958vw, 22.75rem);
        height: clamp(11.375rem, 5.688rem + 18.958vw, 22.75rem);
    }
}
</style>
