const whyInsocialBlock = {
    autoPromotion: "12075b2a-5005-4225-a090-6ec10f3a2c00",
    autoBoost: "a93b88a4-2ddd-45d4-d629-4b787f751200",
    commentTracker: "23fb209a-75e1-46ab-cccb-eba13c1ae500",
    hashtagGenerator: "bb0d44b2-e347-495b-1964-1e5276aa5800",
    influencersDiscovery: "8df3e650-5f50-405e-781a-73952544e500",
    directMessaging: "6eb1b848-f21b-4d69-5854-d9a527125c00",
    schedulePosting: "39eb68d0-72b9-4326-99ca-0032838bec00",
};

const cygLikes = {
    desktop: "a3e70464-580f-4483-263d-16a986f18800",
    mobile: "da87e395-00fa-4a2e-0faa-01c862899a00",
};

const insocialForYou = {
    image_1: "70d24822-d6c1-4611-9a48-d56b88882800",
    image_2: "198614d9-905a-4279-79b0-4d92a8c91f00",
    image_3: "58ad3beb-c22c-4593-e3c3-47742efe7000",
    image_4: "714ed5a6-c602-4e33-7fed-64dedf4da000",
    image_5: "ac936fc9-262a-4fab-c3fe-e1489dcb9d00",
    image_6: "351fb896-7edc-4179-f304-c13ee7d98f00",
};

const trustedTools = {
    candy: "b03fb9c3-6a7f-4728-a5f2-b6047bed4600",
};

const influencers = {
    layer_1: "0f4f86ce-bb7a-4279-9f98-d58640ba5500",
    layer_2: "92341180-deab-4669-bf51-86fb3312af00",
    layer_3: "d95c37c7-7106-4a76-5a58-0b3225db6d00",
    woman: "2ffeecff-b2f1-4910-e784-ceef874b8800",
};

const autoBoost = {
    icons: "7096e47a-b0be-4998-0f60-8fc093d50c00",
    iconsRow: "ac08d431-cfd5-4eb0-7e61-660c92664b00",
};

const avatars = {
    insta_avatar: "b68e17d6-e7f2-4a9f-50ac-1a6ba0bd1d00",
    avatar_man_1: "fc3abf51-123a-4245-0623-2b204cc2f300",
    avatar_man_2: "b29e30f1-165b-405b-2c23-db88c8474c00",
    avatar_man_3: "3eafafc6-3c68-4337-8151-93fb78b02400",
    avatar_man_4: "14185d19-bdd8-4577-2b77-37aff8ce9a00",
    avatar_man_5: "e9487042-c15f-44a1-ce8e-01be217c1d00",
    avatar_man_6: "2b41ee28-3528-47f1-2eed-8aaa8ffb3a00",
    avatar_man_7: "fd79eb5d-c4aa-4726-611a-0d14b14a6200",
    avatar_woman_1: "d6398606-dbe5-41d7-9760-8cfde0c54b00",
    avatar_woman_2: "5788ae16-73bd-40ec-6546-d520fa8dfa00",
    avatar_woman_3: "30cc04d4-7fdb-4bf9-094b-6893b8a93000",
    avatar_woman_4: "ee0727c4-3193-4c29-47d8-9f3f98822e00",
    avatar_woman_5: "d6e1cef4-99d5-4ecb-8ca9-bfdef590b400",
    avatar_woman_6: "150a23bd-aa20-42da-d42a-daaea1398500",
    avatar_woman_7: "5c0ebf6c-2059-480b-4ae9-34878ddf2800",
    avatar_woman_8: "9583ce3c-9ff5-4ea4-b670-3b4f5210b000",
};

const reviews = {
    company_1: "ab7b6cbd-fbbe-4cda-5d99-7d5bdd162500",
    company_2: "79fbfc05-7433-47e2-e252-b19d557fd200",
    company_3: "bda74b65-dfbb-4a41-9068-64e173d88200",
    company_4: "8e159de8-948a-43cd-e257-b5bf10c2e000",
    company_5: "124aa9b5-b162-42ad-d279-073c75618d00",
};

const howItWorks = {
    autoBoost_1: "9edbdef8-e644-42fc-4151-dc24aa985300",
    autoBoost_2: "49166cc7-f1cb-4f07-5a4f-f72af9355b00",
    autoBoost_3: "335a3203-ba48-4642-383a-c4af51eb3600",
    autoPromotion_1: "43cebb3e-aa85-4393-af56-cfbb026bb000",
    autoPromotion_2: "52bac851-e61c-4a0a-1159-c7c228897a00",
    autoPromotion_3: "77f864cd-fcc4-46df-f1d5-47ec110be900",
    influencers_1: "9aaeb4d3-62e6-46ae-d539-30b1dd6ef900",
    influencers_2: "e9022db6-03e4-45d1-c8d7-72d021fa2c00",
    influencers_3: "57c0e383-5358-4636-5f85-04c52b32aa00",
    directMessaging_1: "db9f5b44-0081-4ebc-9556-655afd0a6500",
    directMessaging_2: "091d5def-bab9-4fe3-37b1-9fa9d6158700",
    directMessaging_3: "18070f2b-7ed4-4f43-82e5-6901ec2e9a00",
    hashtagGenerator_1: "b23d058f-0417-43b1-8c57-f1a6867a6000",
    hashtagGenerator_2: "9a640f59-e803-4167-3353-ea6fce241b00",
    hashtagGenerator_3: "6f5a7ff8-a437-4385-76e5-5c3c1ad90300",
    schedulePosting_1: "32618f9c-19dc-4ce5-0566-4436e57f4d00",
    schedulePosting_2: "87c829dd-c5ef-41dd-0fa8-f447ced00300",
    schedulePosting_3: "7927c734-f8da-497a-3fdc-0f9106ec8900",
    commentTracker_1: "2c962c1f-36ac-4998-d77a-991adce1c100",
    commentTracker_2: "d294e4c3-0290-402b-eded-7b29a8e5a300",
    commentTracker_3: "88471031-aead-489f-9f3c-85e95910b600",
};

const logos = {
    header: "9ead7f21-3861-4526-e8b4-029c04117300",
    footer: "0b295260-133c-4993-c01a-4af922be2f00",
    og_image:
        "https://imagedelivery.net/cp9N9lAl81VEBJZWdrNT3g/97c0f72a-895e-44a5-66fa-524081a50d00/default",
};

const robot = {
    lhand: "0dcdf9b0-439e-4c86-996e-96ddac9fd900/default",
    rhand: "bdc2a689-5811-4371-aeb5-646f629c6400/default",
    body: "d034584a-1148-4c89-6529-7a604ff60d00/default",
};

export {
    whyInsocialBlock,
    insocialForYou,
    trustedTools,
    influencers,
    howItWorks,
    autoBoost,
    cygLikes,
    reviews,
    avatars,
    logos,
    robot,
};
