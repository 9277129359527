import type {
    DehydratedState,
    VueQueryPluginOptions,
} from "@tanstack/vue-query";
// Nuxt 3 app aliases
import {
    VueQueryPlugin,
    QueryClient,
    hydrate,
    dehydrate,
} from "@tanstack/vue-query";

export default defineNuxtPlugin((nuxt) => {
    const vueQueryState = useState<DehydratedState | null>("vue-query");

    // Modify your Vue Query global settings here
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                gcTime: 1000 * 60 * 60 * 24,
                staleTime: 1000 * 60 * 60 * 24,
            },
        },
    });
    const options: VueQueryPluginOptions = {
        queryClient,
    };

    nuxt.vueApp.use(VueQueryPlugin, options);

    if (process.server) {
        nuxt.hooks.hook("app:rendered", () => {
            vueQueryState.value = dehydrate(queryClient);
        });
    }

    if (process.client) {
        hydrate(queryClient, vueQueryState.value);
    }
});
