import { useScriptGoogleTagManager, useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({"id":"G-2716WNP37X"})
    const googleTagManager = useScriptGoogleTagManager({"id":"GTM-TD8DZQS","scriptInput":{"async":true,"defer":false,"crossorigin":false,"referrerpolicy":false}})
    return { provide: { $scripts: { googleAnalytics, googleTagManager } } }
  }
})